/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useState } from 'react';
import { ChevronLeftIcon, SearchIcon } from '@chakra-ui/icons';
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react';
import type { LngLat } from '@yandex/ymaps3-types';
import { useRouter } from 'next/navigation';
import { YMap, YMapComponentsProvider, YMapCustomClusterer, YMapDefaultFeaturesLayer, YMapDefaultSchemeLayer } from 'ymap3-components';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { useAppSelector } from '~/lib/hooks';

import { CustomClusterMobile } from '../custom-cluster-mobile';
import { CustomMarkerMobile } from '../custom-marker-mobile';

import styles from './all-address-popup.module.css';

export const AllAddressPopup = ({ filteredSalePoints, onClose }: { filteredSalePoints: any; onClose: () => void }) => {
    const router = useRouter();
    const latSelectedCity = useAppSelector((state) => state.user.data.address.lat);
    const lonSelectedCity = useAppSelector((state) => state.user.data.address.lon);

    const [selectedTab, setSelectedTab] = useState<'list' | 'map'>('list');
    const [searchAddress, setSearchAddress] = useState('');

    const [filteredAddress, setFilteredAddress] = useState<any[] | []>(filteredSalePoints);

    const marker = useCallback((feature: any) => {
        return <CustomMarkerMobile isPartnerModal={true} feature={feature} showAllAddress={false} />;
    }, []);

    const cluster = useCallback((coordinates: LngLat, features: any[]) => {
        return <CustomClusterMobile isPartnerModal={true} coordinates={coordinates} features={features} />;
    }, []);

    const handleInputChange: InputProps['onChange'] = (event) => {
        const value = event.target.value;
        setSearchAddress(value);

        setFilteredAddress(filteredSalePoints.filter((item: any) => item.address.toString().toLowerCase().includes(value.toLowerCase())));
    };

    useEffect(() => {
        if (filteredSalePoints.length) {
            setFilteredAddress(filteredSalePoints);
        }
    }, [filteredSalePoints.length]);

    return (
        <Drawer isOpen={true} placement='bottom' onClose={onClose} autoFocus={false} size='full' returnFocusOnClose={false}>
            <DrawerOverlay />

            <DrawerContent border={'none'}>
                <DrawerHeader display='flex' flexDirection={'row'} justifyContent={'space-between'}>
                    <ButtonCore onClick={onClose}>
                        <ChevronLeftIcon w={'24px'} h={'24px'} color='rgba(0, 0, 0, 1)' />
                    </ButtonCore>
                    <TypographyCore view='bold' size='m'>
                        {filteredSalePoints[0].partner?.name}
                    </TypographyCore>
                    <div />
                </DrawerHeader>
                <DrawerBody borderBottomWidth={'none'} overflowY={'hidden'} p={0}>
                    <div className={styles['buttons-container']}>
                        <button
                            className={selectedTab === 'list' ? styles['active-button'] : styles['inactive-button']}
                            onClick={() => setSelectedTab('list')}
                        >
                            <TypographyCore>Списком</TypographyCore>
                        </button>
                        <button
                            className={selectedTab === 'map' ? styles['active-button'] : styles['inactive-button']}
                            onClick={() => setSelectedTab('map')}
                        >
                            <TypographyCore>На карте</TypographyCore>
                        </button>
                    </div>
                    {selectedTab === 'map' ? (
                        <div className={styles['container-map']}>
                            <YMapComponentsProvider apiKey={'fda85a65-b535-4ad0-b669-6d093411767b'}>
                                <YMap
                                    key='map'
                                    location={{
                                        center: filteredAddress[0]?.geometry?.coordinates ?? [lonSelectedCity, latSelectedCity],
                                        zoom: 13,
                                    }}
                                    mode='vector'
                                >
                                    <YMapDefaultSchemeLayer />
                                    <YMapDefaultFeaturesLayer />
                                    {/* @ts-ignore */}
                                    <YMapCustomClusterer gridSize={64} marker={marker} cluster={cluster} features={filteredAddress} />
                                </YMap>
                            </YMapComponentsProvider>
                            <ButtonCore
                                view='primary'
                                size='large'
                                onClick={() => router.push(`/partner/${filteredSalePoints[0].partner.id}`)}
                                className={`${styles['button-map']} mt-4 mb-4`}
                            >
                                Перейти к каталогу
                            </ButtonCore>
                        </div>
                    ) : (
                        <div className={styles['list-container']}>
                            <InputGroup mb={'12px'} size='md' colorScheme='gray'>
                                <InputLeftElement pointerEvents='none'>
                                    <SearchIcon color='gray.300' />
                                </InputLeftElement>
                                <Input
                                    variant='filled'
                                    backgroundColor='rgba(244, 244, 244, 1)'
                                    placeholder='Поиск по адресам'
                                    className={styles['input']}
                                    onChange={handleInputChange}
                                    borderRadius={'8px'}
                                    value={searchAddress}
                                    _hover={{
                                        borderColor: 'rgba(3, 77, 33, 1)',
                                    }}
                                    _active={{
                                        borderColor: 'rgba(3, 77, 33, 1)',
                                    }}
                                    _focusVisible={{
                                        borderColor: 'rgba(3, 77, 33, 1)',
                                    }}
                                />
                            </InputGroup>
                            <div className={styles['address-container']}>
                                {filteredAddress.map((item: any) => (
                                    <div key={item.id} className={styles['address-item-container']}>
                                        <TypographyCore size='m' view='bold' className='mb-8'>
                                            {item.partner.name}
                                        </TypographyCore>
                                        <TypographyCore size='m' view='regular' color='secondary' className='mb-8'>
                                            {item.address}
                                        </TypographyCore>
                                    </div>
                                ))}
                            </div>
                            <ButtonCore
                                view='primary'
                                size='large'
                                onClick={() => router.push(`/partner/${filteredSalePoints[0].partner.id}`)}
                                className={`${styles['button']} mt-4 mb-4`}
                            >
                                Перейти к каталогу
                            </ButtonCore>
                        </div>
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
