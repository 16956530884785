import { apiSlice } from '../rtk-query/api';

export interface Partner {
    id: number;
    name: string;
    logo: string;
    cover: string;
}

export interface SalePoints {
    address: string;
    lat: number;
    lon: number;
    partner: Partner;
    phone: string;
}

export const partnersApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPartners: builder.query<{ data: { items: Partner[] } }, { $filter?: string }>({
            query: ({ $filter }) => ({ url: `v1/partners?${$filter}` }),
            transformErrorResponse: () => 'Возникла проблема при получении данных о партнерах. Попробуйте еще раз',
        }),
        getPartnersSalePoints: builder.query<{ data: { items: SalePoints[] } }, { $filter?: string }>({
            query: ({ $filter }) => ({ url: `v1/partners/sale_points?${$filter}` }),
            transformErrorResponse: () => 'Возникла проблема при получении торговых точек партнера. Попробуйте еще раз',
        }),
    }),
});

export const { useGetPartnersQuery, useGetPartnersSalePointsQuery } = partnersApi;
